import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/jUk6GEwYDRo">
    <SEO title="Jesus > Angels - Hebrews" />
  </Layout>
)

export default SermonPost
